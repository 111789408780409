import { ref, computed, watch } from 'vue'
import { defineStore } from 'pinia'
import axios from 'axios'
import router from '@/router'

let existingUser = null

try {
    existingUser = JSON.parse(localStorage.getItem('user'))
    console.log('local storage user:', existingUser)
} catch (error) {
    console.log('error loading user data', error)
    localStorage.removeItem('user')
}

if (!existingUser) {
    console.log('no existing user, pushing to login')
    localStorage.removeItem('user')
    router.push({ name: 'landing' })
}

export const useUserStore = defineStore('user', {
    state: () => ({
        user: existingUser,
        notifications: null,
        unreadNotifications: false,
        messages: null,
        unreadMessages: false,
    }),
    getters: {

    },
    actions: {
        async login(loginDetails) {
            try {
                // login user
                const userResponse = await axios.post(`/api/auth/login/`, loginDetails, {
                    withCredentials: true,
                    credentials: 'include'
                })
                console.log('login full res', userResponse)
                console.log('cookies from res:', userResponse.cookie)
                console.log('login data: ', userResponse.data.user)

                // check unread notifications
                const notificationsResponse = await axios.get(`/api/notification/user/${userResponse.data.user._id}`)
                console.log('notifications response:', notificationsResponse)
                this.unreadNotifications = notificationsResponse.data.data.some(notification => notification.read === false)
                console.log('unread notifications?', this.unreadNotifications)

                // check unread messages
                const messagesResponse = await axios.get(`/api/message/${userResponse.data.user._id}`)
                console.log('messages response:', messagesResponse)
                this.unreadMessages = messagesResponse.data.data.some(message => message.read === false)
                console.log('unread messages?', this.unreadMessages)

                // set data
                const userData = userResponse.data.user
                this.notifications = notificationsResponse.data
                this.messages = messagesResponse.data
                this.user = userData

                // update local storage
                localStorage.setItem('user', JSON.stringify(userData))
                console.log('local storage user:', JSON.parse(localStorage.getItem('user')))
                router.push('/dashboard')
                return 'login success'

            } catch (error) {
                console.log('error logging in:', error)
                return error.response.data.message
            }
            
        },
        async googleLogin(userData) {
            console.log('google login user data:', userData)
            // check unread notifications
            const notificationsResponse = await axios.get(`/api/notification/user/${userData._id}`)
            console.log('notifications response:', notificationsResponse)
            this.unreadNotifications = notificationsResponse.data.data.some(notification => notification.read === false)
            console.log('unread notifications?', this.unreadNotifications)

            // check unread messages
            const messagesResponse = await axios.get(`/api/message/${userData._id}`)
            console.log('messages response:', messagesResponse)
            if (messagesResponse.data.length > 0) {
                this.unreadMessages = messagesResponse.data.some(message => message.read === false)
            }
            console.log('unread messages?', this.unreadMessages)

            // set data
            this.notifications = notificationsResponse.data
            this.messages = messagesResponse.data
            this.user = userData

            // update local storage
            localStorage.setItem('user', JSON.stringify(userData))
            console.log('local storage user:', JSON.parse(localStorage.getItem('user')))
            router.push('/dashboard')
        },
        setProfilePic(url) {
            console.log('UPDATE USER STORE PROFILE PIC:', url)
            // Update Local Storage
            let updatedUser = JSON.parse(localStorage.getItem('user'))
            updatedUser.profilePic = url
            localStorage.setItem('user', JSON.stringify(updatedUser))
            // Update State
            this.user.profilePic = url
            console.log('UPDATED USER STORE VALUE', this.user)
        },
        updateUser(userData) {
            console.log('updating user data:', userData)
            // Update Local Storage
            localStorage.setItem('user', JSON.stringify(userData))
            // Update State
            this.user = userData
        },
        updateUserBuddies(buddies) {
            console.log('updating user buddies:', buddies)
            const updatedUser = this.user
            updatedUser.buddies = buddies
            localStorage.setItem('user', JSON.stringify(updatedUser))
            this.user = updatedUser
        },
        updateUserSessions(sessions) {
            console.log('updating user sessions:', sessions)
            this.user.sessions = sessions
            localStorage.setItem('user', JSON.stringify(this.user))
        },
        updateUserNotifications(updatedNotifications) {
            console.log('updating user notifications', updatedNotifications)
            this.notifications = updatedNotifications
            this.unreadNotifications = updatedNotifications.some(notification => notification.read === false)
        },
        removeUserNotification(notification) {
            console.log('removing user notification', notification)
        },
        updateMessages(updatedMessages) {
            this.messages = updatedMessages
            this.unreadMessages = updatedMessages.some(message => message.read === false)
        },
        logout() {
            this.user = null
            localStorage.removeItem('user')
            router.push({ name: 'landing'})
        }
    }
})

// export const useUserStore = defineStore('userStore', () => {

//     // State
//     const user = ref(JSON.parse(localStorage.getItem('user')) || null)
//     const test = ref(4)

//     // Watcher
//     // watch(user, (newValue) => {
//     //     localStorage.setItem('user', JSON.stringify(newValue))
//     // })
    
//     // Getters
//     const getUser = computed(() => user.value)
//     const getTest = computed(() => test.value)


//     // function getUser() { return user.value }
//     function getSessions() { return user.value.sessions }
//     function getSubjects() { return user.value.subjects }
//     function getPicture() { return user.value.profilePic }

//     // Setters
//     async function loginUser(loginDetails) {

//         // axios.defaults.withCredentials = true
//         axios.post(`/api/auth/login/`, loginDetails, {
//             withCredentials: true,
//             credentials: 'include'
//         })
//         .then(res => {
//             console.log('login full res', res)
//             console.log('login data: ', res.data.data)
//             setUser(res.data.data)
//             // router.push('/dashboard')
//         })
//         .catch(error => {   
//             console.error(error)
//         });

//     }

//     function setProfilePic(url) {
//         console.log('UPDATE USER STORE PROFILE PIC:', url)
//         user.value.profilePic = url
//         console.log('UPDATED USER STORE VALUE', user.value)
//     }

//     function setUser(payload) {
//         user.value = payload
//     }

//     function clearUser() {
//         user.value = null
//         localStorage.removeItem('user')
//     }

    // function toggleUser() {     // Testing Purposes Only
    //     if (user.value) {
    //         user.value = null
    //         console.log('user logged out', user.value)
    //     } else {
    //         user.value = {
    //             _id: "63d0e8782e7c8b0ccdfae7e6",
    //             username: "Jeremy",
    //             email: "learntogetherapp@outlook.com",
    //             location: {
    //                 city: "Antarctica",
    //                 lat: "-76.528123056651",
    //                 lng: "166.20491542458535"
    //             },
    //             dob: "0063-08-15",
    //             gender: "m",
    //             verified: true,
    //             __v: 0
    //         }
    //         console.log('user logged in', user.value)
    //     }
    // }

//     return { 
//         user, 
//         getUser,
//         // getUsername,
//         getSessions,
//         getSubjects,
//         getPicture,
//         loginUser,
//         setUser,
//         setProfilePic,
//         clearUser,
//         // toggleUser
//     }

// })

// Create default user for testing
// Create real user from Login form

// if (this.user) {
//     this.user = null
// } else {
//     this.user = {
//         _id: "63d0e8782e7c8b0ccdfae7e6",
//         username: "Jeremy",
//         email: "learntogetherapp@outlook.com",
//         location: {
//             city: "Antarctica",
//             lat: "-76.528123056651",
//             lng: "166.20491542458535"
//         },
//         dob: "0063-08-15",
//         gender: "m",
//         verified: true,
//         __v: 0
//     }
// }


// {
        // _id: "63d0e8782e7c8b0ccdfae7e6",
        // username: "Jeremy",
        // email: "learntogetherapp@outlook.com",
        // location: {
        //     city: "Antarctica",
        //     lat: "-76.528123056651",
        //     lng: "166.20491542458535"
        // },
        // profilePic: 'https://res.cloudinary.com/dvj68swnz/image/upload/v1677303947/lt/profile-pics/default-abstract_f8nmhf.jpg',
        // dob: "0063-08-15",
        // gender: "m",
        // verified: true,
        // __v: 0
        // }