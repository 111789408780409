<template>
  <!-- <nav>
    <router-link class="menu-closed-item" to="/sessions">Sessions</router-link>
    <router-link class="menu-closed-item" to="/">Dashboard</router-link>
    <router-link class="menu-closed-item" to="">Menu</router-link>
  </nav> -->
<!-- 
  <div>unread messages: {{ userStore.unreadMessages }}</div>
  <div>unread notifications: {{ userStore.unreadNotifications }}</div> -->

  <router-view/>
</template>

<script setup>
// Imports
import { useUserStore } from '@/stores/UserStore'

// Setup
const userStore = useUserStore()

// Global State
const user = userStore.getUser
const testdata = userStore.testData

console.log('.env loaded...', process.env)

// Lifecycle
// onMounted(() => {
//   userStore.setUser()
// })
</script>

<style>

/* GLOBAL */

* {
  margin: 0;
  padding: 0;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  font-family: 'Ubuntu', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2b2b2b;
}

:root {
  --primary-color: #3D8B22;
  --secondary-color: #266610;
  /* --tertiary-color: #134900; */
  --tertiary-color: #0f3a00;
  --light-color: #8eb97f;
  --darker-grey: #2b2b2b;
  --dark-grey: #4b4b4b;
  --medium-grey: #7d7d7d;
  --light-grey: #C1C1C1;
  --bg-grey: #dedede;
  --accent-color: #C6F801;
  --red-color: #aa1111;
}

#app {
  /* color: #2c3e50; */
  color: #404040;

  background-image: url('@/assets/leaf-bg.jpeg');
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  min-height: 100vh;
}

a {text-decoration: none;}

.light-text {
  font-weight: lighter;
}

/* ICONS */

.getLocationIcon {
  /* Get Location Icon */

  position: absolute;
  left: 73.81%;
  right: 24.74%;
  top: 31.77%;
  bottom: 66%;

  /* forest green */
  background: #3D8B22;

}

/* SPLASH */

.splash {
  height: 20vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  background-image: url('@/assets/web-dev.jpeg');
  background-size: cover;
  background-position: center;
}

/* FORMS */

.vertical-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1.5rem 0;
  text-align: center;
  width: 100%;
}
.vertical-form input, select {text-align: center;}

.horizontal-form {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 1.5rem 0;
  width: 100%;
}
.horizontal-form > div {
  margin: 0.5rem;
}

.form-item-wide {width: 100%;}
.form-item-small {width: auto;}
.form-item-half {width: 45%;}

::placeholder {
  color: #C1C1C1;
  font-style: italic;
}
.select:invalid {
  font-style: italic;
  color: var(--light-grey);
}

label {
  display: block;
  font-weight: bold;
}

input {
  border: none;
  border-bottom: 1px solid #C1C1C1;
  padding: 0.5rem;
  font-size: 1rem;
  width: 80%;
  margin-bottom: 2rem;
  text-align: center;
}
input:focus {border-color: var(--primary-color);}

.text-input, .email-input {
  padding: 0.7rem;
  border-radius: 0;
  border: none;
  border-bottom: 1px solid #C1C1C1;
  outline: none;
  width: 16rem
}
.text-input:focus {border-color: var(--primary-color);}

.select-input {
  border: none;
  border-bottom: 1px solid #C1C1C1;
  padding: 0.67rem;
  font-size: 1rem;
  /* font-style: italic; */
  color: #303030;
  margin-bottom: 2rem;
  width: 100%;
  height: 41px;
}
.select-input:focus {outline-width: 0;}

.password-input {
  display: flex;
  justify-content: center;
}
/* #password-eye-icon {
  position: absolute;
  margin-top: 0.75rem;
  margin-left: 15rem;
  width: 16px;
  height: 16px;
} */

.textarea-input {
  padding: 0.7rem;
  border-radius: 0;
  border: none;
  border-bottom: 1px solid #C1C1C1;
  outline: none;
  font-size: 1rem;
  text-align: center;
  margin-bottom: 2rem;
}
textarea:focus {border-color: var(--primary-color);}

.date-input {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  width: 100%;
}

.search-input {
    width: 100%;
    padding: 0.8rem 1rem;
    color: white;
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 1);
    font-weight: 700;
    margin-right: -1.4rem;
    margin-bottom: 0;
    text-align: left;
    box-sizing: border-box;
}
.light .search-input {
    border: 1px solid var(--medium-grey);
    color: var(--dark-grey);
}
.light .search-input::placeholder {
    color: var(--light-grey);
}
.error-message {
  font-style: italic;
  color: var(--red-color);
  margin-bottom: 1rem;
}

/* FILTERS */
.filter {
    padding: 0.5rem 1rem 0.5rem 0.5rem;
    margin-left: 1rem;
    font-size: 1rem;
    border: none;
    border-bottom: 4px solid var(--accent-color);
    box-sizing: border-box;
    color: var(--accent-color);
    background-color: transparent;
}

.light .filter {
    color: var(--primary-color);
    border-bottom: 4px solid var(--primary-color);
}

/* WRAPPERS */

.view-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: auto;
  padding: 2rem 0 20vh 0;
  width: 80vw;
}

.left-panel {
  width: 60%;
  margin-right: 1.5rem;
}
.right-panel {
  width: 40%;
}

.white-middle, .dark-middle {
  padding: 2rem;
  box-sizing: border-box;
  border-radius: 1rem;
  margin-bottom: 1.5rem;
}

.white-middle {background-color: white; color: var(--dark-grey);}
.dark-middle {background-color: rgba(0, 0, 0, 0.5); color: white;}

.white-middle h1, .white-middle h2 {margin-bottom: 1rem;}
.dark-middle h1, .dark-middle h2 {margin-bottom: 1rem; color: white;}

.center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

/* MOBILE WRAPPERS */
.white-top, .white-bottom, .dark-top, .dark-bottom {
  background-size: cover;
  background-position: center;
  min-height: 30px;
  max-height: 40px;
  display: none;
}
.white-top, .dark-top {
  margin-top: 1rem; 
  /* transform: translateY(1px); */
}
.white-bottom, .dark-bottom {
  margin-bottom: 1rem;
  /* transform: translateY(-1px); */
}

.white-top {background-image: url(@/assets/white-top.svg);}
.white-bottom {background-image: url(@/assets/white-bottom.svg);}
.dark-top {background-image: url(@/assets/dark-top.svg);}
.dark-bottom {background-image: url(@/assets/dark-bottom.svg);}

/* OVERLAY */
.overlay-bg {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  overflow: scroll;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  padding: 5vh 0 20vh 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.overlay-wrapper {
  background-color: white;
  padding: 3rem;
  border-radius: 1rem;
  margin-bottom: 24vh;
  box-sizing: border-box;
}

.overlay-wrapper > h1 {
  margin-bottom: 2rem;
}

.overlay-wrapper .close-overlay {
    padding: 1rem;
    font-style: italic;
    color: var(--tertiary-color);
    cursor: pointer;
    text-align: center;
    font-size: 1rem;
}

@media only screen and (max-width: 1080px) {
  .view-wrapper {
    flex-direction: column;
    max-width: 1080px;
    align-items: center;
    height: auto;
  }
  .left-panel, .right-panel {
    width: 100%;
    margin: 0;
  }
  .view-wrapper > div {
    width: 100%;
  }
  .white-top, .white-bottom, .dark-top, .dark-bottom {
    min-height: 59px;
    max-width: 1080px;
  }
  .white-middle, .dark-middle {
    margin-bottom: 2rem;
  }
}

@media only screen and (max-width: 600px) {
  .view-wrapper {
    width: 100vw;
  }
  .white-middle, .dark-middle {
    border-radius: 0;
    padding: 0.5rem 2rem;
    margin-bottom: 0;
  }
  .white-top, .white-bottom, .dark-top, .dark-bottom {
    display: block;
    min-height: 42px;
  }
  .overlay-wrapper {
    width: 100%;
    border-radius: 0;
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

</style>