function validateEmail(email) {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
}

function validatePassword(password) {
    // Regex pattern to check for at least one numeric digit and one special character
    // const regex = /^(?=.*\d)(?=.*[!@#$%^&*()+=-\?;,./{}|\":<>\[\]\\\' ~_]).{8,}$/;
    // Regex pattern to check for at least one numeric digit
    const regex = /\d/;
    return regex.test(password);
}

module.exports = {
    validateEmail,
    validatePassword,
}