import { createRouter, createWebHistory } from 'vue-router'
import LandingView from '../views/LandingView'

const routes = [

  // ONBOARD
  {
    path: '/',
    name: 'landing',
    component: LandingView
  },
  {
    path: '/auth/verifyemail/:id/:jwt',
    name: 'verifyEmail',
    component: () => import ('../views/VerifyEmailDoneView.vue')
  },
  {
    path: '/onboard',
    name: 'onboard',
    component: () => import ('../views/OnboardView.vue'),
    meta: { requiresAuth: true }
  },

  // HOME
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('../views/DashboardView.vue'),
    meta: { requiresAuth: true }
  },

  // SESSIONS
  {
    path: '/session/all',
    name: 'mySessions',
    component: () => import('../views/SessionsView.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/session/create',
    name: 'createSession',
    component: () => import('../views/SessionCreateView.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/session/:id',
    name: 'sessionDetails',
    component: () => import('../views/SessionDetailsView.vue'),
    meta: { requiresAuth: true },
    props: true
  },
  {
    path: '/session/rate/:id',
    name: 'rateSession',
    component: () => import('../views/SessionRatingView.vue'),
    meta: { requiresAuth: true },
    props: true
  },
  // {
  //   path: '/session/:id/invite',
  //   name: 'sessionInvite',
  //   component: () => import('../views/SessionInviteView.vue'),
  //   meta: { requiresAuth: true },
  //   props: true
  // },

  // SUBJECTS

  {
    path: '/subject/view/:uriName',
    name: 'subjectDetails',
    component: () => import('../views/SubjectDetailsView.vue'),
    meta: { requiresAuth: true }
  },

  // SEARCH
  {
    path: '/search/:searchFor',
    name: 'search',
    component: () => import('../views/Search.vue'),
    props: (route) => ({ searchFor: route.params.searchFor || 'session' }),
    meta: { requiresAuth: true }
  },

  // BUDDIES
  {
    path: '/buddies/',
    name: 'buddies',
    component: () => import('../views/BuddiesView.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/buddies/messages/:id',
    name: 'buddyMessages',
    component: () => import('../views/BuddyMessagesView.vue'),
    meta: { requiresAuth: true }
  },
 
  // PROFILE
  {
    path: '/profile/:id',
    name: 'profile',
    component: () => import('../views/ProfileView.vue'),
    // only authenticated users can view profiles
    meta: { requiresAuth: true }
  }, 
  {
    path: '/profile/reviews/:id',
    name: 'userReviews',
    component: () => import('../views/ProfileReviewsView.vue'),
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/profile/',
    name: 'myProfile',
    component: () => import('../views/ProfileMyView.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/profile/pic',
    name: 'changeProfilePic',
    component: () => import('../views/ProfilePicView.vue'),
    meta: { requiresAuth: true }
  },

  // SETTINGS
  {
    path: '/settings',
    name: 'settings',
    component: () => import('../views/Settings.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/notifications',
    name: 'notifications',
    component: () => import('../views/NotificationsView.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/privacy-policy',
    name: 'privacyPolicy',
    component: () => import('../views/PrivacyPolicyView.vue'),
    meta: { requiresAuth: false }
  },
  {
    path: '/terms-of-service',
    name: 'termsOfService',
    component: () => import('../views/TermsOfServiceView.vue'),
    meta: { requiresAuth: false }
  },

  // ERRORS
  {
    path: '/401',
    name: 'unauthorized',
    component: () => import('../views/401-Unauthorized.vue')
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router