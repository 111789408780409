import { createApp } from 'vue'
import { createPinia } from 'pinia'
import { useUserStore } from '@/stores/UserStore'
import App from './App.vue'
import router from './router'


// Setup
const app = createApp(App)
const pinia = createPinia()
app.use(pinia)
const userStore = useUserStore()


router.beforeEach(async (to, from, next) => {
    // if route meta requiresAuth = true, and user is not logged in
    if (to.meta.requiresAuth && !userStore.user) {
        // redirect to login
        next('/')
    } else {
        // continue
        next()
    }
})

// Middleware
app.use(router)

// Run App
app.mount('#app')
// createApp(App).use(router).mount('#app')