<template>
    <button>{{text}}</button>
</template>

<script>
export default {
    props: {
        text: String
    }
}
</script>

<style scoped>
    button {
        font-size: 1rem;
        width: 200px;
        padding: 0.8rem 1rem;
        color: white;
        background-color: var(--primary-color);
        border-radius: 10px;
        border: none;
        text-align: center;
        font-weight: 700;
        display: flex;
        justify-content: center;
        align-items: center;
        /* margin: auto; */
    }
    button:hover {
        cursor: pointer;
    }
    .dark {background-color: var(--secondary-color);}
    .darker {background-color: var(--tertiary-color);}
    .light {background-color: var(--light-color);}
    .red {background-color: var(--red-color);}
    .disabled {
        font-style: italic;
        opacity: 0.5;
    }
    .disabled:hover {
        cursor: not-allowed;
    }
    .small {
        width: auto;
        margin: 0;
    }
    .wide {
        width: 100%;
        box-sizing: border-box;
    }
    .fat {
        padding: 2rem 1.5rem;
    }
    .spaced {
        margin: 1rem 0;
    }
    .right {
        margin-left: auto;
        margin-right: 0;
    }
    .left {
        margin-left: 0;
        margin-right: auto;
    }
    .center {
        margin-left: auto;
        margin-right: auto;
    }
    .margintop {
        margin-top: 1rem;
    }
</style>