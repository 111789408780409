<template>
    <div class="wrapper">

        <div class="landing">
            <img class="landing-logo" src="@/assets/images/brand/logo-white.svg" alt="Logo">
            <div class="landing-text">
                <h1>LearnTogether</h1>
                <p>Make Friends, Share Knowledge.</p>
                <!-- <p>Create sessions.</p>
                <p>Meet buddies.</p>
                <p>Learn better.</p> -->
            </div>
            <!-- <div class="call-to-action">
                <div class="mobile-btns">
                    <Button text="Sign Up" class="landing-btn" />
                    <Button text="Log In" class="landing-btn dark" />
                </div>
                <div class="nearby">
                    <div class="location">
                        <h3>Check out nearby study sessions</h3>
                        <div>Taipei, TW</div>
                    </div>
                    <span class="iconify" data-icon="ci:location"></span>
                </div>
            </div> -->
        </div>

        <div class="onboard">
            
            <!-- LOGIN -->
            <form v-if="showLogin" id="login-form" action="#">
                <h1 class="form-header">Welcome Back</h1>
                <input class="text-input" v-model="loginEmail" type="text" placeholder="Email Address">
                <div class="password">
                    <input class="text-input" v-model="loginPassword" :type="showPass ? 'text' : 'password'" placeholder="Password">
                    <div class="show-pass" @click="togglePass"><span id="eye-icon" class="iconify" data-icon="fluent:eye-12-filled"></span></div>
                </div>
                <div v-if="onboardErrorMessage" class="error-message">{{ onboardErrorMessage }}</div>
                <Button @click="loginUser" v-if="!loading" class="web-onboard-btns center" text="Log In" />
                <LoadingSpinner v-if="loading" class="loading-spinner" />
                <div><span class="toggle-form-btn" @click="toggleForm">Sign Up</span> Instead</div>
            </form>

            <!-- SIGNUP -->
            <form v-if="showLogin===false" id="signup-form" action="#">
                <h1 class="form-header">Welcome</h1>
                <input class="text-input" v-model="signupEmail" type="text" placeholder="Email Address">
                <div class="password">
                    <input class="text-input" v-model="signupPassword" :type="showPass ? 'text' : 'password'" placeholder="Password">
                    <div class="show-pass" @click="togglePass"><span id="eye-icon" class="iconify" data-icon="fluent:eye-12-filled"></span></div>
                </div>
                <div v-if="onboardErrorMessage" class="error-message">{{ onboardErrorMessage }}</div>
                <Button @click="signupUser" v-if="!loading" class="web-onboard-btns center" text="Sign Up" />
                <LoadingSpinner v-if="loading" class="loading-spinner" />

                <div><span class="toggle-form-btn" @click="toggleForm">Log In</span> Instead</div>
            </form>
            
            <GoogleAuthSignIn class="google-sign-in" />

            <div class="policy-terms-links" >
                By signing up, you agree to our
                <router-link :to="{ name: 'privacyPolicy' }">Privacy Policy</router-link>
                    and 
                <router-link :to="{ name: 'termsOfService' }">Terms Of Service</router-link>
            </div>
        </div>
        
        <!-- VERIFY EMAIL SENT -->
        <div v-if="showVerifyEmail" class="overlay-bg" @click="showVerifyEmail = false">
            <div class="overlay-wrapper" @click.stop="">

                <div class="verify-overlay">
                    <h1>Verification email sent</h1>
                    <p>email was sent to</p>
                    <p id="verify-email-address">{{ signupEmail }}</p>
                    <Button @click="resendEmailVerification()" class="onboard-btn dark wide" text="Resend Verification" />
                    <p class="close-overlay" @click="showVerifyEmail = false">Close</p>
                </div>

            </div>
        </div>

    </div>
</template>

<script setup>
// Imports
import { ref } from 'vue'
import { useUserStore } from '@/stores/UserStore'
import axios from 'axios'
import router from '@/router'
import { validateEmail, validatePassword } from '@/utils/formValidation'

// Components
import Button from '@/components/Button.vue'
import GoogleAuthSignIn from '@/components/GoogleAuthSignIn.vue'
import LoadingSpinner from '@/components/LoadingSpinner.vue'

// Setup
const userStore = useUserStore()

// Data
const showLogin = ref(false)
const showPass = ref(false)

const loginEmail = ref(null)
const loginPassword = ref(null)

const signupEmail = ref(null)
const signupPassword = ref(null)
const newUser = ref(null)
const showVerifyEmail = ref(false)

const onboardErrorMessage = ref(null)

const loading = ref(false)

// Logic
async function signupUser() {
    console.log('signup')

    console.log('pass:', signupPassword.value)
    console.log('password verification:', validatePassword(signupPassword.value))

    if (!signupEmail.value || !validateEmail(signupEmail.value)) {
        onboardErrorMessage.value = "Please provide a valid email"
        return
    }

    if (!signupPassword.value || signupPassword.value.length < 6 || !validatePassword(signupPassword.value)) {
        onboardErrorMessage.value = "Please provide a password that is at least 6 characters long and includes a number"
        return
    }

    try {
        loading.value = true
    
        // Create user
        const user = {
            username: signupEmail.value,
            email: signupEmail.value,
            password: signupPassword.value,
            verified: false,
            // location: {
            //     city: 'none',
            //     lat: '0',
            //     lng: '0',
            // },
            // gender: 'female',
            // verifiedDate: null
        }
        
        console.log('creating user...', user)
        
        const response = await axios.post(`/api/user/`, user)
        console.log('successfully created user:', response)
        newUser.value = response.data.data
        showVerifyEmail.value = true
    } catch (error) {
        onboardErrorMessage.value = error.response.data.message
        console.log('response data:', error.response.data)
        loading.value = false
    }


}

async function resendEmailVerification() {

    console.log("resend email verification to user:", newUser.value._id)

    // Auth Route Email
    axios.get(`/api/auth/send-email-verify?userId=${newUser.value._id}&userEmail=${newUser.value.email}`)
    .then(function (response) {
        console.log(response)
        console.log(user, 'email verification resent')
    })
    .catch(function (error) {
        console.log(error)
    })

}

function toggleForm() {
    showLogin.value = !showLogin.value
}

function togglePass() {
    showPass.value = !showPass.value
    console.log('show pass')
}

async function loginUser() {
    loading.value = true
    const loginDetails = {
        email: loginEmail.value,
        password: loginPassword.value,
    }
    const loginStatus = await userStore.login(loginDetails)
    console.log('loginStatus:', loginStatus)
    onboardErrorMessage.value = loginStatus
    loading.value = false
}

</script>


<style scoped>
    .wrapper {
        width: 100vw;
        height: 100vh;
        display: flex;
    }

    /* FORM */
    .onboard {
        width: 40%;
        background-color: white;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    #signup-form, #login-form {
        display: flex;
        flex-direction: column;
        /* width: 400px; */
        margin: 2rem auto;
        align-items: center;
    }

    .form-header {
        margin-bottom: 2rem;
    }

    .text-input {
        margin: 1rem 0;
        padding: 0.7rem;
        text-align: center;
        border-radius: 0;
        border: none;
        border-bottom: 1px solid #C1C1C1;
        width: 360px;
        outline: none;
    }

    .text-input:focus {
        border-color: var(--primary-color);
    }

    .password {
        margin-bottom: 3rem;
        display: flex;
        align-items: center;
    }
    .password > input {
        margin-left: -3rem;
    }
    .show-pass {
        margin: -2rem;
    }

    #eye-icon {
        color: black;
        max-height: 1rem;
        max-width: 1rem;
    }

    #eye-icon:hover {
        cursor: pointer;
        opacity: 1;
    }

    #terms {
        margin: 0.4rem;
        width: auto;
    }

    .agree-terms {
        display: flex;
        align-items: center;
        margin-bottom: 1.5rem;
        font-style: italic;
        font-weight: 100;
    }

    .web-onboard-btns {
        width: 80%;
        margin-bottom: 2rem;
    }

    .toggle-form-btn {
        font-weight: 900;
        font-style: italic;
        text-decoration: none;
        color: var(--secondary-color);
    }
    .toggle-form-btn:hover {
        cursor: pointer;
    }

    .error-message {
        margin-top: -1rem;
        margin-bottom: 1rem;
    }

    .google-sign-in {
        margin-bottom: 2rem;
    }

    .policy-terms-links {
        margin: 2rem auto;
        width: 250px;
        text-align: center;
    }

    .policy-terms-links > a {
        font-style: italic;
        color: var(--secondary-color);
    }

    /* SPLASH */
    .landing {
        flex-grow: 1;
        background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url('@/assets/landing-2-blur.jpg');
        background-size: cover;
        background-position: center;
        color: white;

        /* padding: 20vh 10vw; */
        padding-left: 10%;
        display: flex;
        align-items: center;
        /* flex-direction: column; */
        /* justify-content: space-between; */
    }
    
    .landing-logo {
        height: 15vh;
    }
    
    .landing-text {
        margin-left: 2rem;
        /* margin: 20vh 10vw; */
    }
    .landing-text > h1 {
        font-size: 4rem;
        font-weight: 900;
        margin-bottom: 1rem;
        color: white;
    }
    .landing-text > p {
        padding: 0.2rem 0;
        font-size: 1.6rem;
        font-weight: 200;
        color: white;
    }

    .onboard-msg {
        font-size: 1rem;
        margin-bottom: 2rem;
    }

    .mobile-btns {
        margin: 0 9vw;
        display: none;
    }
    .landing-btn {
        margin: 10px 0;
    }

    .nearby {
        margin: 3rem;
        text-align: end;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
    .location {
        font-weight: 200;
        font-style: italic;
    }
    .location * {
        color: white;
    }
    .iconify {
        height: 4rem;
        width: 4rem;
        opacity: 0.5;
        padding-left: 0.2rem;
    }

    .verify-overlay {
        padding: 4rem;
    }
    .verify-overlay > h1 {
        margin: 1rem;
    }
    .verify-overlay > p {
        color: var(--medium-grey);
        font-style: italic;
        text-align: center;
        font-size: 1rem;
    }
    #verify-email-address {
        font-size: 1.2rem;
        font-weight: normal;
        font-style: normal;
        margin: 0.5rem 0 4rem 0;
        color: var(--secondary-color);
    }
    
    .loading-spinner {
        margin-bottom: 2rem;
    }

    @media only screen and (max-width: 1280px) {
        .landing {
            padding-left: 5%;
        }
    }

    /* TABLET */
    @media only screen and (max-width: 1200px) {
        .wrapper {
            flex-direction: column;
        }
        .landing {
            padding: 1.5rem;
            /* min-height: 40vh; */
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
        .landing-logo {
            margin: 1rem;
        }
        .landing-text {
            text-align: center;
            margin-bottom: 2rem;
            margin-left: 0;
        }
        .landing-text > h1 {
            font-size: 3.2rem;
            font-weight: 900;
            margin-bottom: 1rem;
            color: white;
        }
        .landing-text > p {
            padding: 0.2rem 0;
            font-size: 1.6rem;
            font-weight: 200;
            color: white;
        }
        .text-input {
            width: 240px;
        }
        .onboard {
            padding: 3rem 2rem;
            box-sizing: border-box;
            width: 100%;
        }
        #signup-form {
            height: auto;
            /* padding: 1.5rem; */
        }
    }

    /* MOBILE */
    /* @media only screen and (max-width: 600px) {
        .landing-btn {
            width: auto;
        }

        .mobile-btns {
            margin: 0 10vw 20vh 10vw;
        }

        .mobile-btns > button {
            width: 100%;
        }

        .text {
            margin: 20vh 10vw 0 10vw;
            width: 80vw;
        }

        .nearby {
            display: none;
        }
    } */

</style>@/utils/formValidation