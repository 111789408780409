<template>

    <div class="spinner">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
    </div>

</template>

<style scoped>

/* SPINNER */

.spinner {
    display: inline-block;
    position: relative;
    width: 32px;
    height: 32px;
}
.spinner div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 32px;
    height: 32px;
    /* margin: 4px; */
    border: 4px solid #fff;
    border-radius: 50%;
    animation: spinner 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: var(--primary-color) transparent transparent transparent;
}
.spinner div:nth-child(1) {
    animation-delay: -0.45s;
}
.spinner div:nth-child(2) {
    animation-delay: -0.3s;
}
.spinner div:nth-child(3) {
    animation-delay: -0.15s;
}
@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

/* PULSE */

.pulse {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}
.pulse div {
    position: absolute;
    border: 4px solid var(--primary-color);
    opacity: 1;
    border-radius: 50%;
    animation: pulse 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.pulse div:nth-child(2) {
    animation-delay: -0.5s;
}
@keyframes pulse {
    0% {
        top: 24px;
        left: 24px;
        width: 0;
        height: 0;
        opacity: 0;
    }
    4.9% {
        top: 24px;
        left: 24px;
        width: 0;
        height: 0;
        opacity: 0;
    }
    5% {
        top: 24px;
        left: 24px;
        width: 0;
        height: 0;
        opacity: 1;
    }
    100% {
        top: 0px;
        left: 0px;
        width: 48px;
        height: 48px;
        opacity: 0;
    }
}


</style>