<template>
    <div class="google-sign-in-container">

        <!-- <div id="g_id_onload"
            data-client_id="616908604787-sa4e3j1l80a9urm4b9r1lk3o76u1acce.apps.googleusercontent.com"
            data-context="signin"
            data-ux_mode="popup"
            data-auto_prompt="false">
        </div>

        <div class="g_id_signin"
            data-type="standard"
            data-shape="rectangular"
            data-theme="outline"
            data-text="signin_with"
            data-size="large"
            data-logo_alignment="left">Google Sign In
        </div> -->

        <div id="g_id_signin"></div>

    </div>
</template>
  
<script setup>
// Imports
import { onMounted } from 'vue';
import axios from 'axios'
import { useUserStore } from '@/stores/UserStore'

// Setup
const userStore = useUserStore()

// Logic
async function handleCredentialResponse(response) {

    console.log('initializing google signin')
    // Parse the credential response
    console.log('google res:', response)
    // const credentialResponse = JSON.parse(response);
    // console.log('google res:', credentialResponse)

    // Get the ID token from the credential response
    const idToken = response.credential;
    console.log('google oauth id token:', idToken)

    // Send the ID token to your server for verification
    // ...
    const data = {
        idToken: idToken
    }
    const res = await axios.post(`/api/auth/oauth/google`, data)
    console.log('api res:', res)

    // Login User
    userStore.googleLogin(res.data.user)

}

// Lifecycle
onMounted(() => {
    // Check Google Libraries
    // if (!window.google) return console.error('Google Identity Services library not loaded');
    // if (!window.google.accounts.id) return console.error('Google Identity Services client not loaded');

    window.onload = function () {
        // Initialize Google Sign In Functionality
        google.accounts.id.initialize({
            client_id: '616908604787-sa4e3j1l80a9urm4b9r1lk3o76u1acce.apps.googleusercontent.com',
            callback: handleCredentialResponse
        });
        // Button Google Sign In
        google.accounts.id.renderButton(
            document.getElementById("g_id_signin"),
            { theme: 'outline', size: 'large', shape: 'rectangular', text: 'signin_with', type: 'standard' }

        );
        // PopupGoogle Sign In
        google.accounts.id.prompt();
    };
  
});



</script>
  

<style scoped>
.google-sign-in-container {
    display: flex;
    justify-content: center;
}
</style>